@import-normalize;
@import "./reset";
@import './variables/fonts';
@import './variables/variables';
@import "variables/global";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');

body {
	font-family: var(--main-font);
}

.app {
	min-height: 100vh;
}

.content-page {
	display: flex;
}

.page-wrapper {
	flex-grow: 1;
	padding: 20px;
}

#box {
	height: 200px;
	background-color: red;
}


.btn {
	padding: 7px 12px;
	border: none;
	margin: 0;
	outline: none;
	font-weight: 300;
	text-transform: uppercase;
	transition: all var(--transition);

	&-inline {
		border: 1px solid var(--black);

		&:hover {
			color: var(--white);
			background-color: var(--bg-color-accent2);
		}
	}
}