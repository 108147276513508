@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  // font-family: 'Futura-pt', 'Nunito Sans', sans-serif;
  font-family: $main-font;

  margin: 0;
  overflow: auto;
}

body {
  --main-bg: #f5f4fd;
  --item-hover: #f9f8fb;
  --secondary-bg: #251d33;
  --main-container-bg: #fff;
  --container-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --text-color: #00071d;
}

body.dark {
  --main-bg: #00071d;
  --item-hover: #1c2339;
  --secondary-bg: #36415e;
  --main-container-bg: #131c31;
  --text-color: #fff;
  --tooltip-bg: #251d33;
  --container-shadow: rgba(#131c31, 0.2) 0px 7px 29px 0px;
  color: white;
}

body {
  background-color: var(--main-bg);
  transition: 0.3s ease-in-out;
}

html {
  scroll-behavior: smooth;
  overflow: overlay;
}

svg {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.active {
  color: black;
}
