@font-face {
  font-family: 'Futura-pt';
  src: url('../assets/fonts/futura-pt-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Futura-pt';
  src: url('../assets/fonts/futura-pt-medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Futura-pt';
  src: url('../assets/fonts/futura-pt-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Futura-pt';
  src: url('../assets/fonts/futura-pt-extra-bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
