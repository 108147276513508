@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;800&display=swap');

$main-font: 'Nunito Sans', sans-serif;

// $primary-text-color: #000000;
// $secondary-text-color: #858598;
// $tertiary-text-color: #a6abb9;
// $error-text: #eb5757;

// $tertiary-bg-color: #f6f7fb;
// $primary-bg-color: #ffffff;
// $secondary-bg-color: #ffbc33;

// $main-content-bg-color: #fafafa;
// $card-bg-color: #3e7adc;

// $success: #8ec63f;
// $error: #fe4b64;

$active-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);

// $desktop: 1280px;
// $tablet: 768px;
// $mobile: 320px;
